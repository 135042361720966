.signup-container {
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  height: 100vh;
  background-color: #f4f4f4;
  gap: 50px;
  padding: 2rem 5rem;
  margin: 0;
  background-image: url(../../images/image1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.signup-container > div:first-child {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 0 0.5rem;
  background-color: #0b0b33;
  border-radius: 5px;
}

.signup-container > div:first-child div {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
  padding: 0;
}

.signup-container > div:first-child div p {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.logo {
  width: 15%;
  height: auto;
}

h2 {
  color: #333;
  margin-bottom: 0.5rem;
  text-align: center;
}

.signup-container > div:first-child > h1 {
  color: red;
}

.signup-container > div:first-child > p {
  color: white;
  margin-bottom: 5px;
  font-style: italic;
  margin-bottom: 0.3rem;
  font-size: 1.2rem;
}

.signup-container > div:last-child form div h2 {
  color: black;
}

.signup-container > div:last-child form div h2 span {
  color: #ffea00;
  font-weight: bold;
  background-color: #0b0b33;
  padding: 0.1rem 0.4rem;
  border-radius: 5px;
  margin: 0;
}

form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #007bff;
  outline: none;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-button > button {
  width: 100%;
  padding: 10px;
  background-color: #0b0b33;
  color: #ffea00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
  font-size: 1.1rem;
}

button:hover {
  background-color: #0056b3;
}

.password-input-wrapper {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: #555;
}

.password-toggle-icon:hover {
  color: #333;
}

.mobile-logo {
  display: none;
}

.login-link {
  color: #ffea00;
  text-decoration: none;
  font-size: 1rem;
  margin-top: 0.5rem;
  text-align: center;
  font-weight: 700;
}

.login-link > a {
  color: white;
  background-color: #0b0b33;
  text-decoration: none;
  padding: 0.2rem;
  border-radius: 5px;
}

.login-link > a:hover {
  background-color: #ffea00;
  text-decoration: underline;
  color: black;
}

@media (max-width: 768px) {
  .signup-container {
    align-items: center;
    justify-content: center;
  }

  .signup-container > div:first-child {
    display: none;
  }

  .mobile-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .mobile-logo a > .school-logo {
    width: 30%;
    height: auto;
    text-align: center;
  }

  .mobile-logo > p {
    margin-bottom: 0;
    font-size: 0.85rem;
  }

  .signup-container > div:last-child {
    margin-top: 0;
  }
}
