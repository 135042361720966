.carousel-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-top: 10.2em;
  }
  
  .carousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.8s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6));
  }
  
  .carousel-text {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 1.2s ease, transform 1.2s ease;
  }
  
  .carousel-text h2 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: bolder;
    color: #FFEA00;
  }
  
  .carousel-text p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }


  .carousel-link {
    background-color: #FFEA00;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }

  .carousel-link span{
    margin-left: 8px; 
    display: flex;
    align-items: center;
  }
  
  .carousel-link:hover {
    background-color: #ff7855;
  }
  
  .left-arrow,
  .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
    font-size: 2rem;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.3s ease; 
  }
  
  .left-arrow {
    left: 20px;
  }
  
  .right-arrow {
    right: 20px;
  }
  
  .left-arrow:hover,
  .right-arrow:hover {
    background-color: rgba(255, 255, 255, 0.8); 
  }
  
  
  .fade-in {
    animation: fadeIn 1.2s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .slide-in {
    opacity: 1;
    transform: translateX(0);
    animation: slideIn 1.2s ease;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  