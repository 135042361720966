.about-message > h2 {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    position: relative;
    color: black;
}

.about-message > h2 span{
    color:#FFEA00;
    font-weight: bold;
    background-color: #0B0B33;
    padding: 0 0.3rem;
    border-radius: 5px;
    margin: 0;
}
  
.about-message > h2::before,
.about-message > h2::after {
    content: '';
    display: block;
    width: 60px; 
    height: 2px; 
    background-color: #0B0B33; 
    margin: 0 auto; 
    border-radius: 2px; 
    position: absolute;
}
  
.about-message > h2::before {
    top: 110%; 
    left: 50%; 
    transform: translateX(-45%); 
}
  
.about-message > h2::after {
    margin-top: 4px;
    top: 115%; 
    left: 50%; 
    transform: translateX(-50%); 
    width: 55px; 
}

.about-message > p{
    text-align: center;
    font-size: 0.95rem;
    margin-top: 1.2rem;
}

.cards-container {
    display: flex;
    justify-content: center; 
    flex-wrap: wrap; 
    gap: 1.25em; 
    row-gap: 3em;
}

.about-card {
    display: flex;
    flex-direction: column;
    max-width: 310px; 
    flex-grow: 1;
    flex-basis: 30%;  
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0.5rem;
    transform: translateX(-100%); 
    opacity: 0; 
    animation: slideInFromLeft 1.5s ease-out forwards;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0); 
        opacity: 1; 
    }
}

.about-img {
    width: 100%; 
    height: auto; 
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.about-card > h3{
    margin-top: 0.7rem;
}

.about-card > p {
    font-size: 0.9rem;
}

.principal-message {
    display: flex;
    gap: 4rem;
    padding: 0 4rem;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5; 
    background-image: url(../../images/image1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    width: 100%;
    margin-top: 1rem;
}

.principal-message div:first-child {
    background-color: #eaeaea;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    max-width: 350px;
}

.principal-message div:first-child img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.principal-message > div:last-child {
    background-color: #090A29;
    max-width: 400px;
    padding: 1.5rem;
}

.principal-message h3:first-of-type {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.1rem;
    color: white;
}

.principal-message h3:last-of-type {
    font-size: 1.3rem;
    margin-bottom: 0.1rem;
    margin-top: 0;
    color: #d72323; 
}

.principal-message p {
    font-size: 1rem;
    line-height: 1.6;
    color: white;
}

@media (max-width: 768px) {
    .principal-message {
        flex-direction: column;
        text-align: center;
    }

    .about-card {
        flex-basis: 100%;
        max-width: 100%;
        padding: 0 0.3rem;
    }

    .cards-container{
        padding: 0 0.5rem;
    }
}

