.container{
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}

form{
    min-width: 350px;
}

label {
    display: block; 
    margin-bottom: 5px; 
    color: black;
    font-weight: bold;
    margin-top: 0.3rem; 
}

select{
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0.3rem;
}

input[type="email"],
input[type="text"],
input[type="tel"],
input[type="date"] {
    width: 100%; 
    padding: 10px; 
    margin-bottom: 15px; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
    font-size: 16px; 
    box-sizing: border-box;
}

input[type="email"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus {
    border-color: #007BFF; 
    outline: none; 
}

form > button {
    background-color: #007BFF; 
    color: white; 
    padding: 10px; 
    border: none; 
    border-radius: 8px; 
    cursor: pointer; 
    font-size: 16px; 
    transition: background-color 0.3s; 
    box-sizing: border-box;
    width: 100%; 
    margin-top: 0.7rem;
}

button:hover {
    background-color: #0056b3; 
}

