.gallery-container {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
  }
  
  .gallery-container > h2 {
    text-align: center;
    position: relative;
    color: black;
  }

  .gallery-container > h2 span{
    color:#FFEA00;
    font-weight: bold;
    background-color: #0B0B33;
    padding: 0.2rem 0.3rem;
    border-radius: 5px;
    margin: 0;
  }
  
  .gallery-container > h2::before,
  .gallery-container > h2::after {
    content: '';
    display: block;
    width: 60px;
    height: 2px;
    background-color: #0B0B33;
    margin: 0 auto;
    border-radius: 2px;
    position: absolute;
  }
  
  .gallery-container > h2::before {
    top: 140%;
    left: 50%;
    transform: translateX(-45%);
  }
  
  .gallery-container > h2::after {
    margin-top: 4px;
    top: 150%;
    left: 50%;
    transform: translateX(-50%);
    width: 55px;
  }
  
  .gallery-slider {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    margin-top: 3rem;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }
  
  .slide.active {
    opacity: 1;
    transform: translateY(0);
  }
  
  .gallery-left-arrow,
  .gallery-right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
    font-size: 2rem;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 10;
  }
  
  .gallery-left-arrow {
    left: 20px;
  }
  
  .gallery-right-arrow {
    right: 20px;
  }
  
  .gallery-left-arrow:hover,
  .gallery-right-arrow:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  