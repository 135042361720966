.page {
  width: 100%;
  height: auto;
}

.tab-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.top-section {
  display: flex;
  padding: 0.5rem 0;
  gap: 1.2rem;
}

.profile-image {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc;
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-pic {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #888;
  font-size: 12px;
  border-radius: 50%;
}

.tab-container > div:last-child {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  margin-left: 7.5rem;
}

.buttons {
  display: flex;
  position: fixed;
  gap: 0.8em;
  flex-direction: column;
  background-color: #0b0b33;
  max-width: 150px;
  padding: 0.7rem 0.5rem;
  height: 100vh;
}

.buttons > button {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  padding: 0 12px;
  background: none;
  border-radius: 6px;
  display: flex;
  gap: 3px;
  align-items: center;
  font-family: inherit;
  cursor: pointer;
  color: inherit;
  width: 100%;
  white-space: nowrap;
  color: white;
  border: none;
}

.buttons > button:nth-last-child(1) {
  margin-top: auto;
  margin-bottom: 0.3rem;
}

.buttons button:hover {
  background-color: rgb(255, 255, 255, 0.1);
}

.buttons button.active {
  background: #ffea00;
  color: #0b0b33;
}

.wrapper {
  width: 100%;
}

.content {
  transition: 0.6s;
  background-color: grey;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.content > p {
  display: flex;
  align-items: center;
  line-height: 13px;
  font-size: 1.2rem;
}

.content > h2 {
  color: #0b0b33;
  margin: 0;
  background-color: #ffea00;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  display: inline-block;
  align-self: center;
}

.block {
  background-color: white;
  width: 100%;
  height: auto;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.block > h2 {
  margin-bottom: 2rem;
  background-color: #0b0b33;
  color: white;
  display: inline-block;
  padding: 0.5rem;
  border-radius: 8px;
  align-self: center;
  font-size: 1.9rem;
  margin-top: 0;
}

.student-info {
  display: flex;
  gap: 2.5rem;
}

.student-info div {
  padding: 0.5rem 1rem;
  min-width: 300px;
}

.student-info div > h3 {
  color: #0b0b33;
  margin: 0;
  background-color: #ffea00;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  display: inline-block;
}

.student-info div > p {
  display: flex;
  text-transform: capitalize;
}

.term-buttons > button {
  padding: 10px 15px;
  margin: 5px;
  background-color: #0b0b33;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.term-buttons > button.active {
  background-color: #444;
}

.term-buttons > button:hover {
  background-color: #ffea00;
  color: #0b0b33;
}

.student-result {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  cursor: pointer;
}
th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.student-result td:hover {
  background-color: transparent;
}
.centered-th {
  text-align: center;
}

th {
  background-color: #0b0b33;
  color: white;
}

td {
  background-color: #f9f9f9;
  text-transform: capitalize;
}

tr:nth-child(even) td {
  background-color: #f1f1f1;
}

.teacher-info,
.principal-info {
  background-color: transparent;
}

.teacher-info label,
.principal-info label {
  color: black;
  margin-bottom: 0.3rem;
}
.spinner {
  width: 56px;
  height: 56px;
  border: 11.2px #0b0b33 double;
  border-left-style: solid;
  border-radius: 50%;
  animation: spinner-aib1d7 0.75s infinite linear;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spinner-aib1d7 {
  to {
    transform: rotate(360deg);
  }
}

.handle-click {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  box-sizing: border-box;
  width: 100%;
  margin: 0.7rem 0;
}

@media (max-width: 768px) {
  .tab-container > div:last-child {
    margin-left: 2rem;
    overflow-y: scroll;
  }

  .buttons {
    max-width: 100%;
    align-items: center;
    height: 100svh;
  }

  .tab-title {
    display: none;
  }

  .buttons:hover {
    width: 10rem;
    transition: width 200ms ease;
    z-index: 12;
  }

  .buttons:hover .tab-title {
    display: block;
  }
  .top-section {
    gap: 1rem;
    align-items: flex-end;
  }

  .profile-image {
    width: 50px;
    height: 50px;
  }

  .greeting > h2 {
    font-size: 1rem;
  }

  .content {
    width: 100%;
    height: auto;
    padding: 0.5rem;
  }

  .block {
    height: auto;
    padding: 0.5rem;
  }

  .student-info {
    flex-direction: column;
  }

  .buttons > button {
    padding: 0.5rem;
    font-size: 10px;
    height: auto;
  }
}
